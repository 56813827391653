<template>
  <div>
    <top-nav></top-nav>
    <div class="home">
    <!-- <div class="homepage"> -->
      <div class="part0">
        <div class="part11">
          <div class="part1Middle">
            <div class="leftContent" v-if="">
              <!-- <img src="./../../assets/imgs/pc/homepage/backg1.png" class="pp1"/> -->
              <div class="part1title" :class="[isID?'IDpart1title':'']">
                {{ $t("home.make_project") }} <span>{{ $t("home.effortless") }}</span>
              </div>
              <div class="part1content" :class="[isID?'IDpart1content':'']">
                {{ $t("home.welcome") }}
              </div>
              <div class="buttonAlign">
                <a v-if="!isID" onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
                    return false;">
                  <button class="part1Button" :class="[isID?'IDpart1Button':'']"><div class="bottomText" :class="[isID?'IDbottomText':'']">{{ $t("home.book_a_demo") }}</div><img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="p12"></button>
                </a>
                <a v-else onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');
                    return false;">
                  <button class="part1Button" :class="[isID?'IDpart1Button':'']"><div class="bottomText" :class="[isID?'IDbottomText':'']">{{ $t("home.book_a_demo") }}</div><img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="p12"></button>
                </a>
              </div>
              
            </div>
            <div :class="{videoMain:isOpenVideo}" v-if="isOpenVideo">
              <div class="videoPosi">
                <div style="padding:45.25% 0 0% 0;position:relative;" class="videoContent">
                  <iframe src="https://player.vimeo.com/video/716632631?h=c391abb874&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Welcome to Arcanite">
                  </iframe>
                  <div class="close-icon" @click.prevent="closeVideo()">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.4141 4.6193L11.0001 10.0334L5.586 4.6193C5.44254 4.49051 5.28144 4.42611 5.10269 4.42611C4.92394 4.42611 4.76627 4.49417 4.62969 4.6303C4.4931 4.76642 4.42504 4.92409 4.4255 5.1033C4.42596 5.28251 4.49035 5.44361 4.61869 5.58661L10.0328 11.0007L4.61869 16.4147C4.4326 16.6008 4.37165 16.8229 4.43581 17.0809C4.49998 17.339 4.65765 17.5001 4.90881 17.5642C5.15998 17.6284 5.38548 17.5674 5.58531 17.3814L10.9994 11.9673L16.4134 17.3814C16.5569 17.5101 16.718 17.5745 16.8967 17.5745C17.0755 17.5745 17.2332 17.5065 17.3697 17.3704C17.5063 17.2342 17.5744 17.0766 17.5739 16.8974C17.5735 16.7181 17.5091 16.557 17.3807 16.414L11.9667 11L17.3807 5.58592C17.5668 5.39984 17.6278 5.17778 17.5636 4.91974C17.4995 4.66169 17.3384 4.50059 17.0803 4.43642C16.8223 4.37226 16.6002 4.43321 16.4141 4.6193Z" fill="#FFFFFF"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="top-rightContent">
              <img src="./../../assets/imgs/pc/homepage/p1.png" class="backgroundP1 parallax-item" data-depth="1.3"/>
              <img src="./../../assets/imgs/pc/homepage/playMediaIcon.png" alt="" class="playButton" @click="openVideo">
              <!-- 新增的手机图片 -->
              <img src="./../../assets/imgs/pc/homepage/effortlessPhone.png" class="effortlessPhone parallax-item" data-depth="0.4"/>
            </div>
          </div>
          </div>
          <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/william463?embed=true"></div>

          <div class="part2RecoginizedIcons">
            <div class="part2-topcontent">
              <trusted></trusted>
            </div>
            <div class="part2-midcontent">
              <featuredIn></featuredIn>
            </div>
            <div class="part2-bottomcontent">
                <recognised></recognised>
            </div>
          </div>
      </div>

      <div class="part25ArcaniteFor">
        <div class="title">{{ $t("home.who_is") }} <span>{{ $t("home.arcanite") }}</span> {{ $t("home.for") }}?</div>
        <div class="tab-differentCustomer">
          <el-tabs type="border-card" class="tabsstyle" > 
            <el-tab-pane :label="$t('home.for_agent')">
              <div class="content">
                <div class="subtitle">{{ $t("home.see_how_jack") }}</div>
                <div class="meetPerson">
                  <img src="./../../assets/imgs/pc/homepage/meetJack.png" alt="" class="meetPerson-pic">
                  <img src="./../../assets/imgs/pc/homepage/playMediaIcon.png" alt="" class="playButton" @click="openJackVideo">
                </div>
                <!-- <div class="video-content">
                  <div style="padding:56.25% 0 0 0;position:relative;height:100%" class="agentVideo">
                    <iframe src="https://player.vimeo.com/video/730175869?h=eca5b740be&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="what_does_Arcanite_do_20220715.mp4"></iframe>
                  </div>
                </div> -->
                <div :class="{videoMain:isOpenJackVideo}" v-if="isOpenJackVideo">
                  <div class="videoPosi">
                    <div style="padding:45.25% 0 0% 0;position:relative;" class="videoContent">
                      <iframe src="https://player.vimeo.com/video/730175869?h=eca5b740be&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Welcome to Arcanite">
                      </iframe>
                      <div class="close-icon" @click.prevent="closeJackVideo()">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.4141 4.6193L11.0001 10.0334L5.586 4.6193C5.44254 4.49051 5.28144 4.42611 5.10269 4.42611C4.92394 4.42611 4.76627 4.49417 4.62969 4.6303C4.4931 4.76642 4.42504 4.92409 4.4255 5.1033C4.42596 5.28251 4.49035 5.44361 4.61869 5.58661L10.0328 11.0007L4.61869 16.4147C4.4326 16.6008 4.37165 16.8229 4.43581 17.0809C4.49998 17.339 4.65765 17.5001 4.90881 17.5642C5.15998 17.6284 5.38548 17.5674 5.58531 17.3814L10.9994 11.9673L16.4134 17.3814C16.5569 17.5101 16.718 17.5745 16.8967 17.5745C17.0755 17.5745 17.2332 17.5065 17.3697 17.3704C17.5063 17.2342 17.5744 17.0766 17.5739 16.8974C17.5735 16.7181 17.5091 16.557 17.3807 16.414L11.9667 11L17.3807 5.58592C17.5668 5.39984 17.6278 5.17778 17.5636 4.91974C17.4995 4.66169 17.3384 4.50059 17.0803 4.43642C16.8223 4.37226 16.6002 4.43321 16.4141 4.6193Z" fill="#FFFFFF"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('home.for_developer')">
              <div class="content">
                <div class="subtitle">Learn how Victor, a busy property developer manages all of his projects effortlessly with Arcanite</div>
                <div class="meetPerson">
                  <img src="./../../assets/imgs/pc/homepage/meetVictor.png" alt="" class="meetPerson-pic">
                  <img src="./../../assets/imgs/pc/homepage/playMediaIcon.png" alt="" class="playButton" @click="openVictorVideo">
                </div>
                <div :class="{videoMain:isOpenVictorVideo}" v-if="isOpenVictorVideo">
                  <div class="videoPosi">
                    <div style="padding:45.25% 0 0% 0;position:relative;" class="videoContent">
                      <iframe src="https://player.vimeo.com/video/731940173?h=94b7617d9d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Welcome to Arcanite">
                      </iframe>
                      <div class="close-icon" @click.prevent="closeVictorVideo()">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.4141 4.6193L11.0001 10.0334L5.586 4.6193C5.44254 4.49051 5.28144 4.42611 5.10269 4.42611C4.92394 4.42611 4.76627 4.49417 4.62969 4.6303C4.4931 4.76642 4.42504 4.92409 4.4255 5.1033C4.42596 5.28251 4.49035 5.44361 4.61869 5.58661L10.0328 11.0007L4.61869 16.4147C4.4326 16.6008 4.37165 16.8229 4.43581 17.0809C4.49998 17.339 4.65765 17.5001 4.90881 17.5642C5.15998 17.6284 5.38548 17.5674 5.58531 17.3814L10.9994 11.9673L16.4134 17.3814C16.5569 17.5101 16.718 17.5745 16.8967 17.5745C17.0755 17.5745 17.2332 17.5065 17.3697 17.3704C17.5063 17.2342 17.5744 17.0766 17.5739 16.8974C17.5735 16.7181 17.5091 16.557 17.3807 16.414L11.9667 11L17.3807 5.58592C17.5668 5.39984 17.6278 5.17778 17.5636 4.91974C17.4995 4.66169 17.3384 4.50059 17.0803 4.43642C16.8223 4.37226 16.6002 4.43321 16.4141 4.6193Z" fill="#FFFFFF"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- <div class="video-content">
                    <div style="padding:56.25% 0 0 0;position:relative;height:100%" class="agentVideo">
                      <iframe src="https://player.vimeo.com/video/731940173?h=94b7617d9d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="meet_victor_20220722"></iframe>
                    </div>
                </div> -->
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <div class="part5CommentsTotal">
        <div class="part5Comments">
          <img src="./../../assets/imgs/pc/homepage/backgg.png" alt="" class="backgg">
          <div class="flex-box">
            <testimonial-card/>
          </div>
          <!-- <div class="blackbox">111</div> -->
        </div>
      </div>
      

        


      <div class="part33-content">
        <!-- <div class="back-pic"></div> -->
        <div class="part33-eachSection" style="padding-top:350px;">
          <div class="simplify-control" style="padding-top:50px;">
            <div class="info-content">
              <div class="info-icon">
                <img src="./../../assets/imgs/pc/homepage/minicons1.png" class="pic" />
              </div>
              <div class="info-title">{{ $t("home.simplify_sales_management") }}</div>
              <div class="info-para" :class="[isID?'IDinfo-para':'']">
                {{ $t("home.unified_sales") }}
              </div>
              <!-- <div class="line"></div> -->
              <div class="bullet-points">
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.simple_navigation") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.track_individual") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.enhanced_channel_communication") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="simplifySales">
            <img src="./../../assets/imgs/pc/homepage/simplifySales1.png" class="simplifyPic parallax-item" data-depth="1.3" />
            <img src="./../../assets/imgs/pc/homepage/simplifySales2.png" class="simplifyPic2 parallax-item" data-depth="0.6" />
          </div>
        </div>

        <div class="part33-eachSection">

          <div class="stayInControl">
            <img src="./../../assets/imgs/pc/homepage/stayInControl1.png" class="stayPic parallax-item" data-depth='1.3' >
            <img src="./../../assets/imgs/pc/homepage/stayInControl2.png" class="stayPic1 parallax-item"  data-depth='0.6'/>
            <img src="./../../assets/imgs/pc/homepage/stayInControl3.png" class="stayPic2 parallax-item"  data-depth='0.6'/>
            </img>
          </div>
          <div class="stay-control">
            <div class="info-content">
              <div class="info-icon">
                <img src="./../../assets/imgs/pc/homepage/stayInIcon.png" class="pic" />
              </div>
              <div class="info-title">{{ $t("home.stay_in_control") }}</div>
              <div class="info-para">
                {{ $t("home.simple_to_use") }}
              </div>
              <!-- <div class="line"></div> -->
              <div class="bullet-points">
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.manage_via_arcanite") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.stay_up_to_date") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot extra-adjust"></div><div class="text">{{ $t("home.control_what_information") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="part33-eachSection">
          <div class="smart-control">
            <div class="info-content">
              <div class="info-icon">
                <img src="./../../assets/imgs/pc/homepage/smartAutomate.png" class="pic" />
              </div>
              <div class="info-title">{{ $t("home.smart_automated") }}</div>
              <div class="info-para">
                {{ $t("home.less_data_entry") }}
              </div>
              <!-- <div class="line"></div> -->
              <div class="bullet-points">
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.fast_track") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.simplifies_all_processes") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.find_contacts") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="smartAutomated">
            <img src="./../../assets/imgs/pc/homepage/circle-blue.png" class="smartPic3"/>
            <img src="./../../assets/imgs/pc/homepage/smartAutomated1.png" class="smartPic parallax-item"  data-depth='1.2'/>
            <img src="./../../assets/imgs/pc/homepage/smartAutomated2.png" class="smartPic1 parallax-item" data-depth='0.6' />
          </div>
        </div>

      </div>
      
      <div class="part4Feature">
        <div class="feature-topSection">
          <div class="part4-title" v-if="!isID">{{ $t("home.more") }} <span>{{ $t("home.features") }}</span></div>
          <div class="part4-title" v-else><span>{{ $t("home.features") }}</span> {{ $t("home.more") }}</div>
        </div>
        <div class="feature-bottomSection">
            <div class="circle1-pos"><img class="circle1" src="./../../assets/imgs/pc/homepage/backCircle1.png" alt=""></div>
            <div class="circle2-pos"><img class="circle2" src="./../../assets/imgs/pc/homepage/backCircle2.png" alt=""></div>
            <div class="feature-card-section">
              <div class="feature-card">
                <img src="./../../assets/imgs/pc/homepage/pic41.png" class="teamIcon" />
                <div class="title">&nbsp;{{ $t("home.team_strength") }}</div>
                <p>The Arcanite team consists of industry professionals with expertise in property, sales, and finance, providing you with comprehensive business support.</p>
              </div>
              <div class="feature-card">
                <img src="./../../assets/imgs/pc/homepage/pic42.png" class="teamIcon" />
                <div class="title">&nbsp;{{ $t("home.first_to_market") }}</div>
                <p>
                  First fast paced, mobile-focused project marketing platform, combining elements of proptech and fintech to streamline off the plan sales.
                </p>
              </div>
              <div class="feature-card">
                <img src="./../../assets/imgs/pc/homepage/pic43.png" class="teamIcon" />
                <div class="title">&nbsp;{{ $t("home.network_effects") }}</div>
                <p>
                  Recruit and expand your own network at lightning speed with every new user adding more synergy to your profile, facilitating additional sales and business growth opportunities.
                </p>
              </div>
              <div class="feature-card">
                <img src="./../../assets/imgs/pc/homepage/pic44.png" class="teamIcon" />
                <div class="title">&nbsp;{{ $t("home.unmatched_value") }}</div>
                <p>
                  Packages to suit all budgets, with clients claiming our products are on average 70% more cost effective than competitors, with more features on offer.
                </p>
              </div>
            </div>
          </div>
        <!-- <img src="./../../assets/imgs/pc/homepage/p4.png" class="pic4" /> -->
      </div>
    </div>
    <div class="foot-adjust">
      <foot-nav></foot-nav>
    </div>
  </div>
</template>
<script>
// import i18n from '@/plugins/i18n'

export default {
  data() {
    return {
      // isRefresh:false,
      isID:false,
      clientHeight:0,
      itemsHTMLCollection:null,
      fadeItemsHTMLCollection:[],
      fadeItemsArray:[],
      fadeFeatureItemsArray:[],
      scrollTop:null,
      itemsArray:[],
      item:'',
      // html:null,
      scrollMax:0,
      input:{
        scrollY:{
          start: 0,
          current: 0,
        },
      },
      output:{
        y: {
            start: 25,
            end: 175,
            current:0,
          },
      },
      // part5LeftNumber:1,
      // part5RightNumber:3,
      // part5MiddleNumber:2,
      // part5All:3,
      // test:'',
      // buttonColor:'part1Button',
      isOpenVideo:false,
      isOpenJackVideo:false,
      isOpenVictorVideo:false,
      // show:true
    };
  },
  created() {
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
   
    // console.log('i18n',this.$i18n)
    // this.autoTest()
    // console.log("window.innerHeight",window.innerHeight)
    // console.log("html.scrollHeight",document.body.scrollTop)
  },
  // computed:{
  //   i18n(){
  //     return this.$t(hello)
  //   }
  // },
  mounted() {
    // if(this.isID && window && window.HubSpotConversations && window.HubSpotConversations.widget){
    //   window.HubSpotConversations.widget.remove(); 
    // }
    // console.log('i18',this)
    this.itemsHTMLCollection = document.getElementsByClassName('parallax-item');

    this.itemsArray = Array.from(this.itemsHTMLCollection);

    this.fadeItemsHTMLCollection = document.getElementsByClassName('part33-eachSection');

    this.clientHeight = document.documentElement.clientHeight;

    this.fadeItemsArray = Array.from(this.fadeItemsHTMLCollection);

    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

    // this.item = this.itemsArray[0];

    this.fadeFeatureItemsArray = Array.from(document.getElementsByClassName('part4Feature'));

    // this.html = document.documentElement;

    // 页面滚动条最多的滚动长度 = 页面总长度 - 窗口高度
    // this.scrollMax = document.documentElement.scrollHeight - window.innerHeight;

    this.input.scrollY.end = document.documentElement.scrollHeight - window.innerHeight;

    this.input.scrollY.range = this.input.scrollY.end - this.input.scrollY.start;
    this.output.y.range = this.output.y.end - this.output.y.start;
    document.addEventListener('scroll',this.handleScroll, true);
    window.addEventListener('mousemove', this.handleResize)

    this.updateInputs();
    this.updateOutputs();
    this.updateEachParallaxItem();
    this.fadeInfoTrans();
    this.fadeFeatureTrans();
		// window.addEventListener("scroll", this.handleScroll, true);
  },
  updated(){
    
  },
  beforeDestroy(){
    document.removeEventListener('scroll',this.handleScroll, true)
    window.removeEventListener('mousemove', this.handleResize,true)
  },
  watch: {
    
  },
  methods: {
    fadeFeatureTrans(){
      let childFeatureTitle = this.fadeFeatureItemsArray[0].getElementsByClassName('feature-topSection')
      let childFeatureBody = this.fadeFeatureItemsArray[0].getElementsByClassName('feature-card')

      if(this.scrollTop + parseInt(this.clientHeight) > this.fadeFeatureItemsArray[0].offsetParent.offsetTop + this.fadeFeatureItemsArray[0].offsetTop + 100){
              childFeatureTitle[0].style.animation  = "fadeIn 1s forwards" 
              if(this.scrollTop + parseInt(this.clientHeight) > this.fadeFeatureItemsArray[0].offsetParent.offsetTop + this.fadeFeatureItemsArray[0].offsetTop + 400){
                childFeatureBody[0].style.animation  = "fadeIn 1s forwards" 
                childFeatureBody[1].style.animation  = "fadeIn 1s forwards" 
                childFeatureBody[2].style.animation  = "fadeIn 1s forwards" 
                childFeatureBody[3].style.animation  = "fadeIn 1s forwards" 
                }
      }
    },
    fadeInfoTrans(){
      this.fadeItemsArray.forEach(element => {

        let childTitleElement = element.getElementsByClassName('info-title')
        let childParaElement = element.getElementsByClassName('info-para')
        // console.log("childParaElementchildParaElementchildParaElement",childParaElement)
        let childBulletElement = element.getElementsByClassName('bullet-points')
        // console.log("滚动的底部位置",this.scrollTop + parseInt(this.clientHeight))
        // console.log("元素的父亲位置",element.offsetParent.offsetTop)
        // console.log("元素的位置",element.offsetParent.offsetTop + element.offsetTop)
        // console.log(element)
        // if(scrollTop + parseInt(clientHeight) > element.offsetParent.offsetTop + element.offsetTop + 300){
          // 元素渐变出现的判断
          if(this.scrollTop + parseInt(this.clientHeight) > element.offsetParent.offsetTop + element.offsetTop + 320){
            childTitleElement[0].style.animation  = "fadeIn 1s forwards" 
            if(this.scrollTop + parseInt(this.clientHeight) > element.offsetParent.offsetTop + element.offsetTop + 400){
              childParaElement[0].style.animation  = "fadeIn 1s forwards" 
              if(this.scrollTop + parseInt(this.clientHeight) > element.offsetParent.offsetTop + element.offsetTop + 500){
                childBulletElement[0].style.animation  = "fadeIn 1s forwards" 
              }
            }
          }
          // else if(scrollTop + parseInt(clientHeight) > element.offsetParent.offsetTop + element.offsetTop + 500){
          //   childParaElement[0].style.animation  = "fadeIn 1s forwards" 
          // }
          // else if(scrollTop + parseInt(clientHeight) > element.offsetParent.offsetTop + element.offsetTop + 600){
          //   childBulletElement[0].style.animation  = "fadeIn 1s forwards" 
          // }
        // }

      })
    },
    handleResize(){
      this.clientHeight = document.documentElement.clientHeight;

      this.input.scrollY.end = document.documentElement.scrollHeight - window.innerHeight;
      this.input.scrollY.range = this.input.scrollY.end - this.input.scrollY.start;
    },
    updateInputs(){
      // scroll y input
      this.input.scrollY.current = document.documentElement.scrollTop;
      this.input.scrollY.fraction = (this.input.scrollY.current - this.input.scrollY.start) / this.input.scrollY.range;


      // this.input.scrollY.current = document.documentElement.scrollTop;
      // // 有问题
      // this.input.scrollY.fraction = (this.input.scrollY.current - itemInput.scrollY.start) / itemInput.scrollY.range;
    },
    updateOutputs(){
      // console.log('this.input.scrollY.fraction',this.input.scrollY.fraction)
      this.output.y.current = this.output.y.start + (this.input.scrollY.fraction * this.output.y.range);
    },
    updateEachParallaxItem(){
      if(this.itemsArray && this.itemsArray.length == 0){
        return;
      }
      this.itemsArray.forEach((item,index) => {
        // var item = this.itemsArray[0];
      // parseFloat 能把string转换成小数点的数字
      var depth = parseFloat(item.dataset.depth, 10);

      // console.log("itemitem.offsetParent.offsetTop",item.offsetParent.offsetTop)

      var itemInput = {
        scrollY: {
          start: item.offsetParent.offsetTop,
          end: item.offsetParent.offsetTop + window.innerHeight,
        }
      }
      
      itemInput.scrollY.range = itemInput.scrollY.end - itemInput.scrollY.start;
      // console.log('this.input.scrollY.current',this.input.scrollY.current)
      // console.log('itemInput.scrollY.start',itemInput.scrollY.start)
      // console.log('itemInput.scrollY.range',itemInput.scrollY.range)
      itemInput.scrollY.fraction = (this.input.scrollY.current - itemInput.scrollY.start) / itemInput.scrollY.range;

      var itemOutputYCurrent = this.output.y.start + (itemInput.scrollY.fraction * this.output.y.range);


      var itemOutput = {
        // x: this.output.x.current - (this.output.x.current * depth),
        y: itemOutputYCurrent * depth,
        // zIndex: this.output.zIndex.range - (10000 * depth),
        // scale: this.output.scale.start + (this.output.scale.range * depth),
        // blur: (depth - this.output.blur.startingDepth) * output.blur.range
      }

        // console.log( 'fractionf', itemInput.scrollY.fraction);
        // item.style.filter = 'blur('+itemOutput.blur+'px)';
        // item.style.zIndex = itemOutput.zIndex;
        // itemOutput.x = 0;
        // itemOutput.y = 200;
        item.style.transform = 'translateY('+itemOutput.y+'px)'
      });
    },
    handleScroll(){
      // 不包括当前窗口高度的滚轮长度
      // var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log("scrollTop",scrollTop)
      
      // 页面总长度
      // var scrollHeight = document.body.scrollHeight
      // console.log("scrollHeight",scrollHeight)
      // 也是窗口高度
      // var clientHeight = `${document.documentElement.clientHeight}`
      // console.log("clientHeight",clientHeight)
      // var WinPosition = scrollTop + clientHeight
      // console.log("WinPosition",WinPosition)

      this.updateInputs();
      this.updateOutputs();
      this.updateEachParallaxItem();
      // 不包括当前窗口高度的滚轮长度

      // var clientHeight = `${document.documentElement.clientHeight}`
      // console.log("clientHeight",clientHeight)

      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;


      this.fadeInfoTrans();
      this.fadeFeatureTrans();
      





      


      // var scrollTop = item33.pageYOffset || item33.documentElement.scrollTop || item33.body.scrollTop;
      // console.log("scrollTop",scrollTop + parseInt(clientHeight))
      // console.log("scrollTop",scrollTop)
      // console.log("scrollTop",scrollTop.clientHeight)



      // if(this.scrollTop + parseInt(clientHeight) > itemsArray11[0].offsetParent.offsetParent.offsetParent.offsetTop + itemsArray11[0].offsetTop + 200){
      //   itemsArray11[0].style.animation = "fadeIn .6s forwards" //添加动画
      // }

      
      // 页面总长度
      // var scrollHeight = document.body.scrollHeight
      // console.log("scrollHeight",scrollHeight)
      // 也是窗口高度
      
      // var WinPosition = scrollTop + clientHeight
      // console.log("WinPosition",WinPosition)

    },
    openVideo(){
      this.isOpenVideo=true;
    },
    closeVideo(){
      this.isOpenVideo=false;
    },
    openJackVideo(){
      this.isOpenJackVideo=true;
    },
    closeJackVideo(){
      this.isOpenJackVideo=false;
    },
    openVictorVideo(){
      this.isOpenVictorVideo=true;
    },
    closeVictorVideo(){
      this.isOpenVictorVideo=false;
    },
    // buttonClick(){
    //   // this.buttonColor='part1ButtonClick'
    //   // this.buttonColor='part1Button'
    // },
    // buttonUp(){
    // },
    // change(number,direction){
    //   if(number==1 && direction==-1){
    //     number=this.part5All
    //   }else if(number==this.part5All && direction==1){
    //     number=1
    //   }
    //   else{
    //     number=number+direction
    //   }
    //   return number
    // },
    // part5Left(){
    //   this.part5LeftNumber=this.change(this.part5LeftNumber,-1)
    //   this.part5RightNumber=this.change(this.part5RightNumber,-1)
    //   this.part5MiddleNumber=this.change(this.part5MiddleNumber,-1)
    // },
    // part5Right(){
    //   this.part5LeftNumber=this.change(this.part5LeftNumber,1)
    //   this.part5RightNumber=this.change(this.part5RightNumber,1)
    //   this.part5MiddleNumber=this.change(this.part5MiddleNumber,1)
    // },
    // changePic(p){
    //   this.part5LeftNumber=this.change(p,-1)
    //   this.part5MiddleNumber=p
    //   this.part5RightNumber=this.change(p,+1)
    // },
    // autoTest(){
    //   this.test=setInterval(() => {
    //       this.part5Right()
    //       // this.show=!this.show
    //       // this.show=!this.show
    //   }, 15000);
    // },
  //   beforeDestroy() {
  //     clearInterval(this.test);
  // }

  },
  components: {
    "top-nav": (resolve) => require(["./TopNav.vue"], resolve),
    "foot-nav": (resolve) => require(["./FooterNav.vue"], resolve),
    player: (resolve) => require(["./Player.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
    "recognised":(resolve) => require(["./Recognised.vue"], resolve),
    "featuredIn":(resolve) => require(["./FeaturedIn.vue"], resolve),
    "testimonialCard":(resolve) => require(["./TestimonialCard.vue"], resolve),
  },
};
</script>
<style lang="scss" scoped>
// wwj
.blackbox{
  // display: inline-block;
  background: black;
  position: relative;
  top: 64px;
  width: 36px;
  height: 36px;
  // z-index: 100;
  // position: fixed;
  // top: 100px;
  left: 0px;
  z-index: 2;
}
.home{
	height: 100%;  
  width: 100vw;
  display: inline-block;
  text-align: center;
  overflow-x:hidden;
  font-family: Poppins;
  margin: 0 auto;
  // margin-top: 90px;
}

// .homepage{
//   // max-width:2560px;
//   // min-width: 1400px;
//   width:1400px;
//   display: block;
//   position: relative;
//   // overflow-x:hidden;
// }
.part0{
  width: 100vw;
  height: 100%;
  position:relative;
  // display: inline-block;
}
// .part1BookDemo{
  // margin:auto;
  // width: 2560px;
  // height: 764px;
  // margin: 0 0 3px;
  // padding: 0 0 13px;
  // background-color: green;
  // background: linear-gradient(-6deg, transparent 280px, #1890ff 0);
  // background:linear-gradient(to left bottom, transparent 50%, 
  //           rgba(0,0,0,.4) 0) no-repeat 100% 0 / 2em 2em, 
  //           linear-gradient(-150deg, transparent 1.5em, #58a 0);
  // z-index: -2;
  // position: relative;
// }
  .part11{
    width:1280px;
    height: 764px;
    margin:auto;
    position: relative;
    padding-bottom:80px;
    // top:-750px;
    .part1Middle{
      margin:auto;
      width:1280px;
      // left:
      // padding: 35px;
      position: relative;
      justify-content: space-around;
      .p1{
        // width: 634.8px;
        height: 533.5px;
        position:absolute;
        bottom:-26px;
        right:-30px;
        // z-index: 1;
      }
      .p11{
        width: 237px;
        height: 421px;
        position:absolute;
        bottom:0;
        right:0;
        // z-index: 2;
      }
      .p12{
        width: 22px;
        height: 22px;
        flex-grow: 0;
        // background-color: #fff;
      }
      // .dogs{
      //   width: 65px;
      //   height: 50px;
      //   // object-fit: contain;
      //   // background-color: #fff;
      // }
      .p13{
        position:absolute;
        bottom:110px;
        left:-30px;
        // z-index: 0;
      }
      .p14{
        position:absolute;
        top:-55px;
        right:40px;
        // z-index: 0;
      }
      .leftContent{
        width: 520px;
        height: 466px;
        padding-top: 188px;
        padding-left: 40px;
        // transform: translateY(30%);
        // opacity: 0;
        // animation:fadeIn 1s forwards .5s;
        // backface-visibility: hidden;
        // animation-fill-mode:forwards;
      }
      .top-rightContent{
        width: 964px;
        height: 805px;
        position: absolute;
        top:10px;
        left:460px;
        .playButton{
          cursor: pointer;
          position:absolute;
          height: 52px;
          width: 52px;
          top: calc(50% - 26px);          
          left: calc(50% - 26px);          
          transtion: translate(-50%,-50%);
          z-index:5;
        }

      }
      .rightContent{
        width:646.8px;
        height: 100vh;
        line-height: 100vh;
        text-align: center;
        font-size: 20vh;
        position:absolute;
        bottom:0;
        right:50px;
      }
      .backgroundP1{
        position:absolute;
        width: 964px;
        height: 805px;
        left: 0;
        // top: -50px;
        z-index: 2;
      }
      .effortlessPhone{
        position: absolute;
        left: 350px;
        top: 30px;
        width: 641px;
        height: 850px;
        z-index: 3;
      }
      .ppp2{
        width: 641px;
        height: 850px;
        // position: absolute;
        // top:-180px;
        // left:348px;
        background-attachment: fixed;
        background-size: cover;
        background-position: center center;
      }
      .part1title{
        width: 481px;
        height: 124px;
        flex-grow: 0;
        font-family: Poppins-Bold;
        font-size: 48px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: left;
        color: #062440;
        word-break: normal;
        span{
          width: 481px;
          height: 124px;
          flex-grow: 0;
          font-family: Poppins-Bold;
          font-size: 48px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.11;
          letter-spacing: normal;
          text-align: left;
          color: #1890FF;
          word-break: normal;
        }
      }

      .IDpart1title{
        width: 475px;
        height: 162px;
        font-size: 36px;
        font-weight: 800px;
        line-height: 150%;
      }
      .part1content{
        margin-top: 20px;
        width: 490px;
        height: 110px;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: normal;
        text-align: left;
        color: #67788C;
      }

      .IDpart1content{
        width: 471px;
        height: 154px;
      }
    
      .part1Button{
        // z-index: 99;
        margin-top: 40px;
        width: 175px;
        height: 42px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px 16px;
        border-radius: 5px;
        background-color: #1890FF;;
        cursor: pointer;
      }
      .part1Button:hover{
        background-color: #096DD9;
      }
      .part1Button:active{
        background-color: #0757AE;
      }
      .IDpart1Button{
        width: 189px;
        height: 42px;
      }
      .bottomText{
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        width: fit-content;
        height: 22px;
        flex-grow: 0;
        white-space: nowrap;
      }
      .IDbottomText{
        font-family: 'Poppins-semibold';
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
.part2RecoginizedIcons{
  margin: 0 auto;
  width: 1210px;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items:center;
  gap: 40px;
}

// wwj
.part25ArcaniteFor{
  width:1210px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 40px;
  height: 100%;
  

  .title{
    width: fit-content;
    height: 80px;
    font-family: 'Poppins-bold';
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 80px;
    color: #062440;
    flex: none;
    order: 0;
    flex-grow: 0;
    white-space: nowrap;
    span{
      font-family: 'Poppins-bold';
      font-style: normal;
      font-weight: 800;
      font-size: 60px;
      line-height: 80px;
      color: #1890FF;
      flex: none;
      order: 0;
      flex-grow: 0;
      white-space: nowrap;
    }
  }


  .tab-differentCustomer{
    .el-tabs{
      width: 1010px;
      position: relative;
    }

    .subtitle{
      margin: 40px auto;
      width: 611.61px;
      height: 44px;
      font-family: 'Poppins-bold';
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #314455;
    }

  

   
  }
 

  .agentVideo{
  }
}

.part33-content{
  // wwj
  // padding-top: 100px;
  width:100%;
  height: 100%;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: relative;
  gap: 400px;
  background: linear-gradient(180deg, #EEF5FC 0%, #F1F7FE 71.91%, rgba(243, 249, 255, 0) 100%);
  z-index: -10;
  .back-pic{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    background: linear-gradient(180deg, #EEF5FC 0%, #F1F7FE 71.91%, rgba(243, 249, 255, 0) 100%);
    width: 100%;
    height: 2850.5px;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%,-50%);
    // width: 1000%;
    position: absolute;
    z-index: -99;
  }
  .back-pic::before{
    content: " ";
    width: 100%;
    height: 150px;
    border-radius: 60%;
    background:#FFFFFF;
    color: #FFFFFF;
    position: absolute;
    left:0;
    top: -75px;


  }
  // .backPic{
  //   width: 1920px;
  //   height: auto;
  // }

}


.part33-content::before{
  content: " ";
  // text-align: center;
  // top: 50%;
  left: 50%;
  top: -120px;
  transform: translateX(-50%);
  width: 200%;
  height: 150px;
  border-radius: 50%;
  background:#FFFFFF;
  color: #FFFFFF;
  position: absolute;
  // left:0;
  // top: -100px;
}

.part33-eachSection{
	display: flex;
	flex-direction: row;
	// justify-content: flex-start;
  // align-items: flex-start;
  // margin: auto;
  // margin-left: 108px;
  // margin-top: 101px;
  // margin-bottom:107px;
  // height: 597px;
  // width: 1210px;
  // justify-content: space-around;
  .simplifySales{
    position:relative;
    // margin-top: 500px;
    z-index: -2;
    top: -200px;
    width: 721px;
    height: 763px;
    .simplifyPic{
      position: absolute;
      width: 1169px;
      height: 1563px;
      top: -900px;
      left: -100px;
    }

    .simplifyPic2{
      position: absolute;
      left: -85px;
      top: 150px;
      width: 537px;
      height: 555px;
    }
  }

  .stayInControl{
    position:relative;
    width: 721px;
    height: 787px;
    top: -200px;
    .stayPic{
      position: absolute;
      width: 1045px;
      height: 787px;
      top: -684px;
      left: -210px;
    }
    .stayPic1{
      position: absolute;
      width: 876px;
      height: 698px;
      top: 50px;
      left: -110px;
    }
    .stayPic2{
      position: absolute;
      width: 593px;
      height: 552px;
      top: 180px;
      left: 350px;
    }
  }
  .smartAutomated{
    position:relative;
    width: 721px;
    height: 597px;
    // top: -200px;
    .smartPic{
      position: absolute;
      width: 509px;
      height: 880px;
      top: -800px;
      left: 50px;
    }

    .smartPic1{
      position: absolute;
      width: 926px;
      height: 761px;
      top: -450px;
      left: 50px;
    }
    .smartPic3{
      position: absolute;
      width: 505px;
      height: 503px;
      top: 0px;
      left: 50px;
    }
  }


  .simplify-control{
    position: relative;
    margin-left: 168px;
    // margin-top: 245px;
  }
  .stay-control{
    position: relative;
    margin-left: 80px;
    // margin-top: 245px;
  }
  .smart-control{
    position: relative;
    margin-left: 168px;
    // margin-top: 0px;
  }
  // wwj bullet-point-body
  .info-content{
    position:relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 466px;
    padding: 10px;
    height: 500px;
    .info-icon{
      width:80px;
      height: 80px;
      // margin: 10px;
      position: relative;
      left: -10px;
      top: 10px;
      // position:absolute;
      .pic{
        width: 80px;
        height: 80px;
      }
    }
    .info-title{
        font-family: Poppins-Bold;
        font-size: 36px;
        font-stretch: normal;
        font-style: normal;
        line-height: 42px;
        letter-spacing: normal;
        color: #062440;
        transform: translateY(110%);
        opacity: 0;
        text-align: left;
      }
      .info-para{
        width: auto;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: normal;
        text-align: left !important;
        color: #314455;
        transform: translateY(110%);
        opacity: 0;
      }
      // .IDinfo-para{
      //   text-align: left !important;
      // }
      .line{
        width: 430px;
        height: 1px;
        background-color: #E6EBF2;
      }
      .bullet-points{
        padding-left: 10px;
        width: 483px;
        // height: 140px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        transform: translateY(110%);
        opacity: 0;
        .bullet-point{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 20px;
        }
        .dot{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #1890ff;
        }
        .extra-adjust{
          position:relative;
          top:-10px;
        }
        .text{
          // margin: 10px 10px 10px 10px;
          // height: auto;
          font-family: Poppins;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: 20px;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
          width: fit-content
        }
      }
  }
  .rigthMainContent{
	  margin-top: 50px;
  }
}
.secondContent{
	margin-top: 160px;
	
}
.pic321{
  width: 1246.7px;
  height: 810.7px;
  position: absolute;
  left: 0;
}
.pic322{
  width: 563px;
  height: 402px;
  position: absolute;
  top: 180px;
  left:150px;
}
.pic331{
  width: 1116px;
  // height: 942px;
  position: absolute;
  right: 0;
  z-index: -1;
}
.pic332{
  width: 250px;
  height: 388px;
  position: absolute;
  right: -90px;
  bottom: -40px;
  z-index: 1;
}
.pic333{
  width: 515px;
  height: 365px;
  position: absolute;
  z-index: 1;
  left: -200px;
  bottom: -420px;
}
.pic334{
  width: 345px;
  height: 369px;
  position: absolute;
  z-index: 1;
  right: -110px;
  bottom: -415px;
}
.pic341{
  width: 584px;
  height: 770px;
  position: absolute;
  right: 80px;
}

.part4Feature{
  margin-top: 300px;
  // margin-bottom: 130px;
  // margin:auto;
  // background-image: url("./../../assets/imgs/pc/homepage/p4.png");
  width:100%;
  height:100%;
  position: relative;
  overflow: hidden;
  text-align: center;
	display: flex;
  flex-direction: column;
	justify-content: center;
	align-items: center;
  z-index: 0;
  gap: 60px;

	  .feature-topSection{
		  text-align: center;
      transform: translateY(110%);
      // opacity: 0;
		  .part4-title{
			  position: relative;
			  font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 800;
        font-size: 60px;
        line-height: 80px;
        color: #062440;
        span{
          position: relative;
          font-family: 'Poppins-Bold';
          font-style: normal;
          font-weight: 800;
          font-size: 60px;
          line-height: 80px;
          color: #1890FF;
        }
		  }
	  }

    .feature-bottomSection{
        // display: flex;
        // flex-direction: row;
        // align-content: center;
        // justify-content: center;
        // gap: 40px;
        height: 500px;
        position: relative;
        // margin: 0 20px;
        // z-index: 101;
        .circle1-pos{
          position: absolute;
          left:127px;
          top: 56px;
        }

        .circle2-pos{
          position: absolute;
          top: -44px;
          right: 146px;
        }

        .circle1{
          width: 237px;
          height: 236px;
        }
        .circle2{
          width: 258px;
          height: 257px;
        }
        .feature-card-section{
          text-align: center;
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: center;
          gap: 40px;
          position: relative;
          opacity: 1;
        }
        .feature-card{
          transform: translateY(100%);
          opacity: 0;
          background: rgba(255, 255, 255, 0.6);
          border: 1px solid #FFFFFF;
          backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
          border-radius: 10px;
          padding: 20px;
          display:flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 232px;
          height: 272px;
          // background:green;
          gap: 20px;
          // z-index: 100;
          .teamIcon{
            width: 54px;
            height: 54px;
            // background: black;
          }
          .title{
            font-family: 'Poppins-Bold';
            font-style: normal;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.06px;
            color: #062440;
            text-align: left;
          }
          p{
            width: 232.5px;
            font-family: 'Poppins';
            font-style: normal;
            font-size: 16px;
            line-height: 22px;
            text-align: left;
            color: #062440;
          }
        }

		  // margin-bottom: 90px;
	    // .section:hover{
	    //   background-color:rgba(6, 36, 64, 0.7);
	    //   border-radius: 5px;
	    //   // backdrop-filter: blur(2px);
	    //   // filter: blur(4px);
	    //   // background-filter: blur(10px);
	    //   // cursor: pointer;
	    //   -webkit-backdrop-filter: blur(10px);
	    //   backdrop-filter: blur(10px);
	    // }
	  }
  // }
}

.flex-box{
  // position: relative;
  margin:auto;
  margin-top:68px;
  width: 100%;
  // height: 883px;
  height: 100%;
  // display: relative;
  // vertical-align: middle;
  text-align: center;
  // align-items: center;
  // justify-content: center;
  z-index: 0;
}
// wwj

.part5CommentsTotal{
  position: relative;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: relative;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%,-50%);
}
.backgg{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 200px;
  left: 0;
  object-fit: fill;
}

/deep/ .swiper-slide{
  // margin-left: 20px;
  // margin-right: 30px;
}
.part5Comments{
  // position: relative;
  // margin:auto;
  // width: 100vw;
  // min-width: 1800px;
  width: 1850px;
  margin: 0;
  // height: 883px;
  height: 100%;
  // display: flex;
  // flex-direction: row;
  // vertical-align: middle;
  // text-align: center;
  // align-items: center;
  // justify-content: center;
  // text-align: center;
  // justify-content: ;
  z-index: 0;
  // .testimonial-title{
  //   font-family: 'Poppins-bold';
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   gap:40px;
  //   font-style: normal;
  //   font-weight: 800;
  //   font-size: 60px;
  //   line-height: 80px;
  //   color: #062440;
  //   flex: none;
  //   order: 1;
  //   flex-grow: 0;
  //   .union-img{
  //     width: 74px;
  //     height: 63px;
  //   }
  // }
  img{
    transition: all 4s;
  }
  h1{
    margin: auto;
    margin-top: 136px;
    margin-bottom: 51px;
    width: 557px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 48px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
  }
  .card{
    width: 1280px;
    display: flex;
    height: 620px;
    // overflow: hidden;
    // overflow-x:hidden;
    position: relative;
    z-index: 0;
  }
  .cardLeft{
    width: 557px;
    height: 452px;
    z-index: 0;
    position: absolute;
    left:-250px;
    border-radius: 5px;
  }
  .cardCoverLeft{
    position: absolute;
    left:-250px;
    width: 557px;
    height: 452px;
    border-radius: 5px;
    z-index: 1;
    background: linear-gradient(to right,rgba(255,255,255,1), rgba(255,255,255,0));
  }
  .cardMiddle{
    width: 557px;
    height: 452px;
    flex-grow: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 51px;
    padding: 0;
    position: absolute;
    left:182px;
    // box-shadow: -5px 5px 10px -4px rgba(0, 0, 0, 0.02), 5px 5px 10px -4px rgba(0, 0, 0, 0.02);
    // box-shadow: 0 2.8px 2.2px 0 rgba(0, 0, 0, 0.02), 0 6.7px 5.3px 0 rgba(0, 0, 0, 0.03), 0 12.5px 10px 0 rgba(0, 0, 0, 0.04), 0 22.3px 17.9px 0 rgba(0, 0, 0, 0.04), 0 41.8px 33.4px 0 rgba(0, 0, 0, 0.05), 0 100px 80px 0 rgba(0, 0, 0, 0.07);
    .cardShadow{
      position: absolute;
      left:182px;
      width: 557px;
      height: 452px;
      box-shadow: 0 2.8px 2.2px 0 rgba(85, 64, 64, 0.02),
      0 6.7px 5.3px 0 rgba(0, 0, 0, 0.03),
      0 12.5px 10px 0 rgba(0, 0, 0, 0.04), 
      0 22.3px 17.9px 0 rgba(0, 0, 0, 0.04), 
      0 41.8px 33.4px 0 rgba(0, 0, 0, 0.05), 
      0 100px 80px 0 rgba(0, 0, 0, 0.07);
      border-radius: 5px;
    }
  }
  .cardRight{
    width: 557px;
    height: 452px;
    position: absolute;
    left:950px;
    border-radius: 5px;
    // background-size: 537px 447px;
  }
  .cardCoverRight{
    width: 557px;
    height: 452px;
    position: absolute;
    left:950px;
    z-index: 1;
    border-radius: 5px;
    background: linear-gradient(to left,rgba(255,255,255,1), rgba(255,255,255,0));
  }
  .bottom{
    display:flex;
    width:557px;
    justify-content: space-between;
    margin: auto;
  }
  .prev{
    // width: 69px;
    height: 20px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #67788c;
    border-bottom: 2px solid #67788c;
  }
  .prev:hover {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #1890ff;
    border-bottom: 2px solid #1890ff;
    cursor: pointer;
  }
  // .dogs{
  //   width: 70px;
  //   height: 10px;
  //   flex-grow: 0;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: flex-start;
  //   align-items: flex-start;
  //   gap: 20px;
  //   padding: 0;
  //   .dogBlue{
  //     width: 10px;
  //     height: 10px;
  //     border-radius: 50%;
  //     background: #1890ff;
  //     display: inline-block;
  //   }
  //   .dogWhite{
  //     width: 10px;
  //     height: 10px;
  //     border-radius: 50%;
  //     background: #d8d8d8;
  //     display: inline-block;
  //   }
  // }
}


.quote{
  position: relative;
  width: 81px;
  height: 76px;
  top:-30px;
  left:582px;
}

.pp1{
  width: 860px;
  height: 642px;
  position: absolute;
  top:50px;
  left: -500px;
  z-index: -1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.videoMain{
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    text-align: center;
    display: block;
}

.videoPosi{
  position: relative;
  margin: 0px auto;
  top: 50%;
  left: 50%;
  max-width: 3140px;
  opacity: 1;
  transform: translate(-50%, -50%);
}

// .videoContent{
//   // display: flex;
//   // flex-direction: row;
//   // justify-content: flex-start;
// }
.close-icon{
  position: absolute;
  top: 0px;
  width: 22px;
  height: 22px;
  right: 7%;
  cursor: pointer;
}


.buttonAlign{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.videoButton{
  // z-index: 99;
  margin-top: 40px;
  width: 168px;
  height: 42px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 1px solid #314455;
  cursor: pointer;
  .videoButtonText{
    font-family: Poppins-Bold;
    font-size: 16px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #314455;
    width: 111px;
    height: 22px;
    flex-grow: 0;
    white-space: nowrap;
  }
  .IDvideoButtonText{
    width: 168px;
    height: 42px;
    font-family: 'Poppins-semibold';
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #314455;
  }

  .videoButtonText{
      color: #314455;
  }
  path{
    fill: #314455;
  }


  &:hover{
    border: 1px solid #062440;
    background-color: #F7F8FA;;
    .videoButtonText{
      color: #062440;
    }
    path{
      fill: #062440;
    }
  }
  &:active{
    border: 1px solid #062440;
    background-color: #F7F8FA;;
    .videoButtonText{
      color: #062440;
    }
    path{
      fill: #062440;
    }
  }
}


@media screen and ( max-width: 1280px ) {
.feature-bottomSection{
  // margin: 0 40px;
  // padding: 0 40px;
}
.feature-card-section{
  gap: 40px !important;
}
.feature-card{
  width: 210px !important;
  height: 300px !important;
}
p{
  width: auto !important;
}

  
}


@media screen and ( min-height: 800px ) {
  .simplifySales{
    position:relative;
    // top: 500px;
    // margin-top: 500px;
    z-index: -2;
    width: 721px;
    height: 763px;
    top: -200px !important;
    .simplifyPic{
      position: absolute;
      width: 1169px;
      height: 1563px;
      top: -900px;
      left: -100px;
    }

    .simplifyPic2{
      position: absolute;
      left: -85px;
      top: 150px!important;
      width: 537px;
      height: 555px;
    }
  }

  .stayInControl{
    position:relative;
    width: 721px;
    height: 787px;
    top: -150px !important;
    .stayPic{
      position: absolute;
      width: 1045px;
      height: 787px;
      top: -684px;
      left: -210px;
    }
    .stayPic1{
      position: absolute;
      width: 876px;
      height: 698px;
      top: 50px!important;
      left: -110px;
    }
    .stayPic2{
      position: absolute;
      width: 593px;
      height: 552px;
      top: 180px!important;
      left: 350px;
    }
  }
  .smartAutomated{
    position:relative;
    width: 721px;
    height: 597px;
    .smartPic{
      position: absolute;
      width: 509px;
      height: 880px;
      top: -750px!important;
      left: 50px;
    }

    .smartPic1{
      position: absolute;
      width: 926px;
      height: 761px;
      top: -450px!important;
      left: 50px;
    }
    .smartPic3{
      position: absolute;
      width: 505px;
      height: 503px;
      top: 0px;
      left: 50px;
    }
  }
  
}


@media screen and ( min-height: 900px ) {
  .simplifySales{
    position:relative;
    // top: 500px;
    // margin-top: 500px;
    z-index: -2;
    width: 721px;
    height: 763px;
    top: 0px !important;
    .simplifyPic{
      position: absolute;
      width: 1169px;
      height: 1563px;
      top: -900px;
      left: -100px;
    }

    .simplifyPic2{
      position: absolute;
      left: -85px;
      top: 0px!important;
      width: 537px;
      height: 555px;
    }
  }

  .stayInControl{
    top: 0px !important;
    .stayPic{
    }
    .stayPic1{
      top: -50px!important;
    }
    .stayPic2{
      top: 80px!important;
    }
  }
  .smartAutomated{
    .smartPic{
      top: -650px!important;
    }

    .smartPic1{
      top: -350px!important;
    }
    .smartPic3{
    }
  }
  
}


@media screen and ( max-height: 650px ) {
  .simplifySales{
    position:relative;
    // top: 500px;
    // margin-top: 500px;
    top: - 650px !important;
    .simplifyPic{
      position: absolute;
      width: 1169px;
      height: 1563px;
      top: -900px;
      left: -100px;
    }

    .simplifyPic2{
      position: absolute;
      left: -85px;
      top: 350px!important;
      width: 537px;
      height: 555px;
    }
  }

  .stayInControl{
    top: -550px !important;
    .stayPic{
    }
    .stayPic1{
      top: 250px!important;
      left: -110px;
    }
    .stayPic2{
      position: absolute;
      width: 593px;
      height: 552px;
      top: 380px!important;
      left: 350px;
    }
  }
  .smartAutomated{
    .smartPic{
      top: -1050px!important;
      left: 50px;
    }

    .smartPic1{
      position: absolute;
      width: 926px;
      height: 761px;
      top: -550px!important;
      left: 50px;
    }
    .smartPic3{
      position: absolute;
      width: 505px;
      height: 503px;
      top: 0px;
      left: 50px;
    }
  }
  
}

@media screen and ( min-height: 1280px ) {
  .simplifySales{
    top: 50px !important;
    .simplifyPic{
      position: absolute;
      width: 1169px;
      height: 1563px;
      top: -900px;
      left: -100px;
    }

    .simplifyPic2{
      position: absolute;
      left: -85px;
      top: -50px!important;
      width: 537px;
      height: 555px;
    }
  }

  .stayInControl{
    top: 250px !important;
    .stayPic{
    }
    .stayPic1{
      top: -180px!important;
    }
    .stayPic2{
      top: -50px!important;
    }
  }
  .smartAutomated{
    .smartPic{
      top: -450px!important;
      left: 50px;
    }

    .smartPic1{
      position: absolute;
      width: 926px;
      height: 761px;
      top: -350px!important;
      left: 50px;
    }
    .smartPic3{
    }
  }
  
}



</style>

<style lang="scss">

.part25ArcaniteFor{

  .meetPerson{
    position:relative;
    // 图片
    .meetPerson-pic{
      width: 990px;
      height: 538px;
      // background: linear-gradient(0deg, rgba(6, 36, 64, 0.2), rgba(6, 36, 64, 0.2));
      border: 1px solid #E5E6EB;
      background: linear-gradient(0deg, rgba(6, 36, 64, 0.2), rgba(6, 36, 64, 0.2));
      // filter: drop-shadow(0px 4px 4px rgba(6, 36, 64, 0.9));
      border-radius: 20px;
    }
    .playButton{
      cursor: pointer;
      position:absolute;
      width: 93px;
      height: 93px;
      top:42%;
      left:45%;
      transtion: translate(-50%,-50%);
      z-index:5;
    }
  }
  .tab-differentCustomer{
    .el-tabs--border-card{
      border: none;
      box-shadow:none;
    }
    .el-tabs__header{
      color:#062440;
      height: 56px;
      // width:384px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom:none;
      background-color: #FFF;
      gap: 12px;
    }
    .el-tabs__nav-wrap .is-top{
      background: rgba(247, 248, 250, 0.6);
      // background-color: rgba(247, 248, 250, 0.6);
      padding:10px 12px;
      border-radius:4px;
    }
    .el-tabs__nav-scroll{
    }
    .el-tabs__nav{
      gap: 12px;
    }
    .el-tabs__nav .is-top{
      display:flex;
      justify-content: center;
      align-items:center;
      min-height: 36px;
    }
    .is-top{
      display:flex;
      justify-content:center;
      align-items:center;
      margin:0;
    }
    .is-active{
      display:flex;
      justify-content:center;
      align-items:center;
      background-color: #1890FF !important;
      color:#FFFFFF !important;
      // margin:0 12px;
    }
    .el-tabs__item{
      color: #314455;
      min-width: 200px;
      max-width: 205px;
      min-height: 24px;
      max-height: 25px;
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      // width: 164px;
      // height: 36px;
      border-radius: 4px;
      // padding:10px 12px;
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active{
      border:none;
    }
  }
  
}

@keyframes fadeIn {
to 
  {
    transform: translateY(0px);
    opacity: 1;
  }
}

// @keyframes fadeOut {
// to 
//   {
//     transform: translateY(110%);
//     opacity: 0;
//   }
// }

// @keyframes transTo {
// to 
//   {
//     transform: translateY(110%);
//     opacity: 1;
//   }
// }
.foot-adjust{
  .footer{
    .firstLine{
      .footerTop{
        padding-top: 0 !important;
      }
    }
  }
}
</style>




